import { ref, Ref, watch } from 'vue'
import Ajv, { JSONSchemaType } from 'ajv'
import { localize } from '@/composables/useLocalMessages'

export const useAjv = <T>(
  entity: Ref<T>,
  schema: Ref<JSONSchemaType<T>>,
  localizeField: (key: string) => string
): [Ref<string[]>, Ref<string[]>] => {
  const fieldsWithError = ref<string[]>([])
  const errorMesages = ref()
  const ajv = new Ajv({ allErrors: true, messages: false })
  watch(
    entity,
    (val) => {
      const validate = ajv.compile(schema.value)
      validate(val)
      validate.errors && localize(validate.errors, localizeField)
      const errors = validate.errors
      if (!errors) {
        fieldsWithError.value = []
        errorMesages.value = []
        return
      }

      fieldsWithError.value = (
        errors?.map(
          ({ instancePath, params }) =>
            instancePath?.replace('/', '') || params?.missingProperty || ''
        ) || []
      ).filter((e) => e)
      errorMesages.value = errors?.map(({ message }) => message)
    },
    { immediate: true }
  )
  return [fieldsWithError, errorMesages]
}
