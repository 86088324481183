import { ref, Ref } from 'vue'
import { batchQuery } from '@/api'
import { isArray } from 'lodash'

const idsMapCache = new Map()

export const useBatch = (
  ids: { id: string; type: string }[]
): Ref<Map<string, IEntity>> => {
  const idsMapRefs = ref<Map<string, IEntity>>(idsMapCache)
  const filteredIds = ids.filter(({ id }) => !idsMapRefs?.value.has(id))
  if (filteredIds.length) {
    setTimeout(async () => {
      const entities = await batchQuery(filteredIds)
      isArray(entities) &&
        entities.forEach((entity) => {
          idsMapRefs.value.set(entity.id, entity)
        })
    })
  }
  return idsMapRefs
}
