import { ErrorObject } from 'ajv'

export const localize = (
  errors: ErrorObject[],
  localizeField: (key: string) => string
): void => {
  if (!(errors && errors.length)) return
  for (const e of errors) {
    let out = ''
    let n
    let cond
    let field
    switch (e.keyword) {
      case 'additionalItems':
      case 'items':
        out = e.instancePath
        n = e.params.limit
        out += 'должно иметь не более, чем ' + n + ' элемент'
        if (n >= 2 && n <= 4) {
          out += 'а'
        } else if (n !== 1) {
          out += 'ов'
        }
        break
      case 'additionalProperties':
        out = 'не должно иметь дополнительных полей'
        break
      case 'anyOf':
        out = 'должно соответствовать одной их схем в "anyOf"'
        break
      case 'const':
        out = 'должно быть равно заданному значению'
        break
      case 'contains':
        out = 'должно содержать значение соответствующее схеме'
        break
      case 'dependencies':
      case 'dependentRequired':
        n = e.params.depsCount
        out += 'должно иметь пол'
        if (n === 1) {
          out += 'е'
        } else {
          out += 'я'
        }
        out +=
          ' ' + e.params.deps + ', когда присутствует поле ' + e.params.property
        break
      case 'discriminator':
        switch (e.params.error) {
          case 'tag':
            out = 'поле "' + e.params.tag + '" должно быть строкой'
            break
          case 'mapping':
            out =
              'значение поля "' +
              e.params.tag +
              '" должно быть в одной из oneOf схем '
            break
          default:
            out = 'должно соответствовать правилу "' + e.keyword + '"'
        }
        break
      case 'enum':
        out = 'должно быть равно одному из разрешенных значений'
        break
      case 'false schema':
        out = 'схема равна false'
        break
      case 'format':
        out = 'должно соответствовать формату "' + e.params.format + '"'
        break
      case 'formatMaximum':
      case 'formatExclusiveMaximum':
        cond = e.params.comparison + ' ' + e.params.limit
        out += 'должно быть ' + cond
        break
      case 'formatMinimum':
      case 'formatExclusiveMinimum':
        cond = e.params.comparison + ' ' + e.params.limit
        out += 'должно быть ' + cond
        break
      case 'if':
        out = 'должно соответствовать схемe "' + e.params.failingKeyword + '"'
        break
      case 'maximum':
      case 'exclusiveMaximum':
        cond = e.params.comparison + ' ' + e.params.limit
        out += 'должно быть ' + cond
        break
      case 'maxItems':
        n = e.params.limit
        out += 'должно иметь не более, чем ' + n + ' элемент'
        if (n >= 2 && n <= 4) {
          out += 'а'
        } else if (n !== 1) {
          out += 'ов'
        }
        break
      case 'maxLength':
        n = e.params.limit
        out += 'должно быть не длиннее, чем ' + n + ' символ'
        if (n >= 2 && n <= 4) {
          out += 'а'
        } else if (n !== 1) {
          out += 'ов'
        }
        break
      case 'maxProperties':
        n = e.params.limit
        out += 'должно иметь не более, чем ' + n + ' пол'
        if (n === 1) {
          out += 'е'
        } else if (n >= 2 && n <= 4) {
          out += 'я'
        } else {
          out += 'ей'
        }
        break
      case 'minimum':
      case 'exclusiveMinimum':
        cond = e.params.comparison + ' ' + e.params.limit
        out += 'должно быть ' + cond
        break
      case 'minItems':
        field = e?.instancePath?.replace('/', '')
        n = e.params.limit
        out += `Поле "${localizeField(
          field
        )}" должно иметь не менее, чем ${n} элемент`
        if (n >= 2 && n <= 4) {
          out += 'а'
        } else if (n !== 1) {
          out += 'ов'
        }
        break
      case 'minLength':
        field = e?.instancePath?.replace('/', '')
        n = e.params.limit
        out += `Поле "${localizeField(
          field
        )}" должно быть не короче, чем ${n} символа`
        if (n >= 2 && n <= 4) {
          out += 'а'
        } else if (n !== 1) {
          out += 'ов'
        }
        break
      case 'minProperties':
        n = e.params.limit
        out += 'должно иметь не менее, чем ' + n + ' пол'
        if (n === 1) {
          out += 'е'
        } else if (n >= 2 && n <= 4) {
          out += 'я'
        } else {
          out += 'ей'
        }
        break
      case 'multipleOf':
        out = 'должно быть кратным ' + e.params.multipleOf
        break
      case 'not':
        out = 'должно не соответствовать схеме в "not"'
        break
      case 'oneOf':
        out = 'должно соответствовать в точности одной схемe в "oneOf"'
        break
      case 'pattern':
        out = 'должно соответствовать образцу "' + e.params.pattern + '"'
        break
      case 'patternRequired':
        out =
          'должно иметь поле, соответствующее образцу "' +
          e.params.missingPattern +
          '"'
        break
      case 'propertyNames':
        out = 'имя поля не соответствует схеме'
        break
      case 'required':
        field = e.params.missingProperty
        out = `Поле "${localizeField(field)}" обязательно`
        break
      case 'type':
        out += e.instancePath + 'должно быть ' + e.params.type
        break
      case 'unevaluatedItems':
        n = e.params.len
        out += 'должно иметь не более, чем ' + n + ' элемент'
        if (n >= 2 && n <= 4) {
          out += 'а'
        } else if (n !== 1) {
          out += 'ов'
        }
        break
      case 'unevaluatedProperties':
        out = 'не должно иметь непроверенных полей'
        break
      case 'uniqueItems':
        out =
          'не должно иметь повторяющихся элементов (элементы ' +
          e.params.j +
          ' и ' +
          e.params.i +
          ' идентичны)'
        break
      default:
        out = 'должно соответствовать правилу "' + e.keyword + '"'
    }
    e.message = out
  }
}
