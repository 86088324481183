import InputField from './fields/InputField.vue'
import EnumField from './fields/EnumField.vue'
import CheckboxField from './fields/CheckboxField.vue'
import RefsField from './fields/RefsField.vue'
import DatesField from './fields/DatesField.vue'
import RadioField from './fields/RadioField.vue'
import DateField from './fields/DateField.vue'

export default {
  string: InputField,
  enum: EnumField,
  checkbox: CheckboxField,
  refs: RefsField,
  dates: DatesField,
  radio: RadioField,
  date: DateField
} as const
