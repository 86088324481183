import { ref, Ref } from 'vue'
// import { REPORTS_LIST } from '../__mock__/data-mocks'
import { fetchReports, deleteEntity, subscribeForReports } from '@/api'

export const useReports = (
  templateTypes?: string[]
): [Ref<Report[]>, Ref<FetchStatus>, (id: string) => void] => {
  const list = ref<Report[]>([])
  const status = ref<FetchStatus>('init')
  setTimeout(async () => {
    // list.value = REPORTS_LIST
    // status.value = list.value.length > 0 ? 'complete' : 'empty'
    try {
      status.value = 'loading'
      list.value = await fetchReports(templateTypes)
      status.value = list.value.length > 0 ? 'complete' : 'empty'
    } catch (error) {
      status.value = 'error'
    }
  })
  subscribeForReports((newReport) => {
    let isMerged = false
    list.value = list.value.map((report) => {
      if (newReport.id === report.id) {
        isMerged = true
        return newReport
      } else {
        return report
      }
    })
    if (
      !isMerged &&
      (templateTypes ? templateTypes.includes(newReport.templateName) : true)
    ) {
      list.value = [newReport, ...list.value]
    }
    status.value === 'empty' &&
      list.value.length > 0 &&
      (status.value = 'complete')
  })
  const deleteReport = async (id: string) => {
    try {
      status.value = 'loading'
      await deleteEntity(id, 'Report')
      list.value = list.value.filter(({ id: reportId }) => id !== reportId)
      status.value = list.value.length > 0 ? 'complete' : 'empty'
    } catch (error) {
      status.value = 'error'
    }
  }
  return [list, status, deleteReport]
}
