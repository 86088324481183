const WARNING_FIELDS = ['reportTemplateNames']
export const validateWidgetProps = <T extends Record<string, unknown>>(
  props: T | undefined
): void => {
  WARNING_FIELDS.forEach((warningField) => {
    !props?.[warningField] &&
      // eslint-disable-next-line no-console
      console.warn(`Property "${warningField}" doesn't provided`)
  })
}
