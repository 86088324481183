import { ref, Ref, onMounted } from 'vue'
// import { TEMLATE_TYPES } from '../__mock__/data-mocks'
import { fetchReportTemplates, createReport } from '@/api'

export const useReportTemplates = (
  templateNames?: string[]
): [
  Ref<ReportTemplate[]>,
  Ref<FetchStatus>,
  (templateId: string, reportOptions: Record<string, unknown>) => void
] => {
  const list = ref<ReportTemplate[]>([])
  const status = ref<FetchStatus>('init')
  const refresh = async () => {
    // list.value = TEMLATE_TYPES
    // status.value = list.value.length > 0 ? 'complete' : 'empty'
    try {
      status.value = 'loading'
      list.value = await fetchReportTemplates(templateNames)
      status.value = list.value.length > 0 ? 'complete' : 'empty'
    } catch (error) {
      status.value = 'error'
    }
  }
  const create = async (
    templateId: string,
    reportOptions: Record<string, undefined>
  ) => {
    try {
      status.value = 'loading'
      await createReport(templateId, reportOptions)
      status.value = 'complete'
    } catch (error) {
      status.value = 'error'
    }
  }
  onMounted(refresh)
  return [list, status, create]
}
