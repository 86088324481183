import './set-public-path'
import { h, createApp } from 'vue'
import PrimeVue from 'primevue/config'
import App from './App.vue'
import { createI18n } from 'vue-i18n'
import { RUSSIAN_TRANSLATIONS } from './translations/ru'
import singleSpaVue from 'single-spa-vue'
import Tooltip from 'primevue/tooltip'
import Button from 'primevue/button'
import FieldLabel from './components/common/FieldLabel.vue'

const i18n = createI18n({
  locale: 'ru',
  legacy: false,
  globalInjection: true,
  messages: {
    ru: RUSSIAN_TRANSLATIONS
  }
})

const vueLifecycles = singleSpaVue({
  createApp,
  appOptions: {
    render() {
      return h(App, {
        props: this.props,
        areas: this.areas,
        mountChildren: this.mountChildren
      })
    }
  },
  handleInstance: (app: ReturnType<typeof createApp>) => {
    app.use(i18n)
    app.use(PrimeVue)
    app.component('FieldLabel', FieldLabel)
    app.component('Button', Button)
    app.directive('tooltip', Tooltip)
  }
})

export const bootstrap = vueLifecycles.bootstrap
export const mount = (props: {
  domElement: Element
  vueAppend: unknown
}): Promise<unknown> => {
  if (props.domElement && props.vueAppend) {
    const el = document.createElement('div')
    props.domElement.appendChild(el)
    props.domElement = el
  }
  return vueLifecycles.mount(props)
}
export const unmount = vueLifecycles.unmount
