import { ref, Ref, computed, ComputedRef } from 'vue'

export const useCurrentByField = <T, G extends keyof T>(
  fieldName: G,
  objects?: ComputedRef<T[] | undefined>
): [Ref<T[G] | undefined>, ComputedRef<T | undefined>] => {
  const currentField = ref<T[G]>()
  const currentEntity = computed(() =>
    objects?.value?.find((o) => o[fieldName] === currentField.value)
  )
  return [currentField, currentEntity]
}
