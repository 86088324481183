import {
  createCamerasConnection,
  listEntities,
  getServerContext,
  getSharedNotificationSocket
} from '@netvision/lib-api-gateway'
import { isArray } from 'lodash'

// interface NGSIOptions {
//   type: string
//   limit?: number
//   offset?: number
//   q?: string
//   attrs?: string
// }

const { gatewayPath } = getServerContext()

export const insensitify = (str: string): string =>
  str
    .split('')
    .map((char) => {
      if (char.match(/[a-zа-я]/i)) {
        return `[${char.toLowerCase()}${char.toUpperCase()}]`
      } else {
        return char
      }
    })
    .join('')
export const subscribeForReports = (
  callback: (report: Report) => void
): void => {
  getSharedNotificationSocket().addListener('Report', callback)
}

export const searchEntities = async (
  entityTypes: string,
  searchField: string,
  searchText: string,
  limit = 25
): Promise<IEntity[]> => {
  const options = {
    type: entityTypes,
    limit,
    keyValues: true
  } as Record<string, unknown>
  if (searchText !== '') {
    options.q = `${searchField}~=${insensitify(searchText)}`
  }
  try {
    const { results }: { results: IEntity[] } = await listEntities(
      createCamerasConnection(),
      { ...options }
    )
    return results
  } catch (error) {
    console.error(error)
    return []
  }
}

export const batchQuery = async (
  batchQueue: {
    id: string
    type: string
  }[]
): Promise<IEntity[]> => {
  try {
    const {
      results
    }: {
      results: IEntity[]
    } = await createCamerasConnection().v2.batchQuery(
      {
        entities: batchQueue
      },
      {
        limit: 1000,
        keyValues: true
      }
    )
    return results
  } catch (error) {
    console.error(error)
    return []
  }
}

export const fetchReports = async (
  templateTypes?: string[]
): Promise<Report[]> => {
  const options = {
    type: 'Report',
    attrs: '*,dateCreated',
    orderBy: '!dateCreated'
  } as Record<string, string>
  isArray(templateTypes) &&
    templateTypes.length > 0 &&
    (options.q = `templateName==${templateTypes.join(',')}`)
  const { results } = await listEntities<Report>(
    createCamerasConnection(),
    options
  )
  return results
}

export const deleteEntity = async (
  id: string,
  type: string
): Promise<boolean> => {
  return createCamerasConnection().v2.deleteEntity({
    type,
    id
  })
}

export const downloadReport = async (
  id: string,
  isPreview?: boolean
): Promise<void> => {
  window.open(
    `${window.location.origin}${gatewayPath}/cameras/v2/blob/${id}?type=Report${
      isPreview ? '&mode=preview' : ''
    }`,
    '_blank'
  )
}

export const fetchReportTemplates = async (
  templateNames?: string[]
): Promise<ReportTemplate[]> => {
  const options = {
    type: 'ReportTemplate'
  } as Record<string, string>
  isArray(templateNames) && (options.q = `name==${templateNames.join(',')}`)
  const { results } = await listEntities<ReportTemplate>(
    createCamerasConnection(),
    options
  )
  return results
}

export const createReport = async (
  id: string,
  renderOptions: Record<string, unknown>
): Promise<boolean> => {
  let renderCommand = {}
  try {
    renderCommand = JSON.parse(
      JSON.stringify(renderOptions).replaceAll(/[<>=;()]/g, '')
    )
  } catch (error) {
    console.error(error)
    return false
  }
  return await createCamerasConnection().v2.updateEntityAttributes(
    {
      id,
      type: 'ReportTemplate',
      renderCommand
    },
    { keyValues: true }
  )
}
