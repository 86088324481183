export const RUSSIAN_TRANSLATIONS = {
  emptyList: 'Список пуст',
  emptyContent: 'Пусто',
  errorContent: 'Ошибка',
  newReport: 'Новый отчет',
  cancel: 'Отмена',
  clear: 'Очистить',
  date: 'Дата',
  reports: 'Отчеты',
  report: 'Отчет',
  error: 'Ошибка',
  form: 'Составить',
  formReport: 'Составить отчет',
  templateType: 'Вид отчета',
  template: 'Шаблон',
  choose: 'Выбрать',
  duplicate: 'Дублировать',
  delete: 'Удалить',
  download: 'Скачать',
  yes: 'Да',
  no: 'Нет',
  all: 'Все',
  to: 'До',
  clean: 'Очистить',
  deleteReport: 'Удалить отчет?',
  inProgress: 'Составляется',
  Failed: 'Ошибка',
  selectPeriod: 'Выберите период',
  wrongFormat: 'Неверный формат',
  for: 'за',
  periods: {
    allTime: 'За все время',
    today: 'Сегодня',
    yesterday: 'Вчера',
    thisWeek: 'Эта неделя',
    thisMonth: 'Этот месяц',
    thisQuarter: 'Этот квартал',
    thisYear: 'Этот год',
    last7Days: 'Последние 7 дней',
    last30Days: 'Последние 30 дней',
    lastWeek: 'Предыдущая неделя',
    lastMonth: 'Предыдущий месяц',
    lastQuarter: 'Предыдущий квартал',
    lastYear: 'Предыдущий год'
  }
}
